define(['app'], function(app) {



  var uspBanner = function() {
    var self = this;

    var _config = {
      banner: '[data-js-element=banner]',
      marqueeSelector: '[data-js-element=marquee]',
      bannerSlideTime: 1000,
    };

    var _init = function(element) {
      self.element = element;
      self.banners = self.element.querySelectorAll(self.config.banner);
      self.visibleBannerIndex = 0; // start at 0 on load
      self.maxBannerIndex = self.banners.length - 1;
      self.bannerShift = self.banners[0].offsetHeight;
      self.marquee = self.element.querySelectorAll(self.config.marqueeSelector)[0];

      if (self.maxBannerIndex > 0) {
        self.scrollMarquee(self.visibleBannerIndex + 1);
      }

      return self;
    };

    var _scrollMarquee = function(bannerInIndex) {
      setTimeout(function() {
        _bannerIn(bannerInIndex);

        setTimeout(function() {
          _scrollMarquee(self.visibleBannerIndex + 1);
        }, self.config.bannerSlideTime);

      }, self.config.bannerSlideTime);
    };

    var _bannerIn = function(bannerIndex) {
      if (self.maxBannerIndex < bannerIndex) {
        self.resetBanners();
      } else {
        self.marquee.setAttribute('style', 'bottom: ' + (bannerIndex * self.bannerShift) + 'px');
        self.visibleBannerIndex = bannerIndex;
      }
    };

    var _resetBanners = function() {
      self.marquee.setAttribute('style', 'bottom: 0');
      self.visibleBannerIndex = 0;
    };


    self.config = _config;
    self.init = _init;
    self.scrollMarquee = _scrollMarquee;
    self.bannerIn = _bannerIn;
    self.resetBanners = _resetBanners;
  };

  return uspBanner;
});
